import { useEffect, useMemo, useState } from 'react';
import './App.css';
import axios from 'axios';
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function App() {
  const [data, setData] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("")
  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }
  
  const fetchData = async () => {
    const res = await axios.get(process.env.REACT_APP_API + '/data-check');
    setData(res.data.data);
  }
  useEffect(() => {
    fetchData();
  },[])

  const clickButton = (value) => {
    openModal()
    setUrl(value);
  }
  const checkFaileSuccess = (data) => {
    if(data){
      switch (data.status){
        case 1:
          return `<span class="success">success<span>`
        case 0:
          return `<span class="faile">failed<span>`
        default:
          return ''
      }
    }else {
      return ''
    }
  }
  const mapData = useMemo(() => {
    const d = data.map(item => {
       const dataCheck = item.check;
       return {
        url: item.url,
        lastcheckViettel: checkFaileSuccess(dataCheck.find(i => i.name == "viettel4g")),
        lastcheckVina: checkFaileSuccess(dataCheck.find(i => i.name == "vina4g")),
        lastcheckMobi: checkFaileSuccess(dataCheck.find(i => i.name == "mobi4g")),
        lastcheckVinaHN: checkFaileSuccess(dataCheck.find(i => i.name == "vina4gHN")),
        lastcheckMobiHN: checkFaileSuccess(dataCheck.find(i => i.name == "mobi4gHN")),
        lastcheckViettelWifi: checkFaileSuccess(dataCheck.find(i => i.name == "viettel")),
        // lastcheckFPT: checkFaileSuccess(dataCheck.find(i => i.name == "fpt")),
        // lastcheckVPNT: checkFaileSuccess(dataCheck.find(i => i.name == "vnpt")),
        countCheck: dataCheck.length ,
        countSuccessCheck: dataCheck.filter(i => i.status == 1).length ,
        countFaileCheck: dataCheck.filter(i => i.status == 0).length ,
       }
    })
    return d;
  },[data])

  const handelSend = async () => {
    if(name && url && status){
      await fetch("https://api.11betuk.xyz/logtele", {
        method: "POST",
        headers: {
        'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "name": name,
          "link": url,
          "status": status
        })
      });
      toast.warn("Thank bạn đã góp ý :))");
      closeModal()
    }else {
      toast.warn("Vui lòng điền đầy đủ");
    }

  }
  return (
    <div className="App">
      <div><span style={{color: "red"}}>Lưu ý:</span> dữ liệu dc cập nhập dựa theo lượt kiểm tra cuối cùng</div>
       <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="form-style-6">
        <h1>Gửi ý kiến {url}</h1>
        <select onChange={(e) => setName(e.target.value)} name="" id="">
          <option value="">--Vui lòng chọn mạng wifi--</option>
            <option value="viettel">viettel</option>
            <option value="fpt">fpt</option>
            <option value="vnpt">vnpt</option>
        </select>
        <select onChange={(e) => setStatus(e.target.value)} name="" id="">
          <option value="">--Vui lòng chọn--</option>
            <option value={1}>Link vào được</option>
            <option value={0}>Link không vào dc</option>
        </select>
        <button onClick={handelSend} className='button'>Gửi</button>
      </div>
      </Modal>
     
      <table className="styled-table">
        <thead>
            <tr>
                <th>Domain</th>
                <th>Viettel(HCM)</th>
                <th>Vina4g(HCM)(ngưng kt)</th>
                <th>Mobi(HCM)(ngưng kt)</th>
                <th>Viettel(HN)</th>
                <th>Vina4g(HN)</th>
                <th>Mobi(HN)</th>
                <th>success/fail</th>
                {/* <th>viettel</th>
                <th>vnpt</th>
                <th>fpt</th>
                <th>success/fail(wifi)</th> */}
                {/* <th>HD</th> */}
            </tr>
        </thead>
        <tbody>
          {mapData.map(item => (
            <tr key={item.url}>
                <td><a style={{color: "blue"}} href={`https://${item.url}`}>{item.url}</a></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckViettel}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckVina}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckMobi}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckViettelWifi}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckVinaHN}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckMobiHN}}></td>
                <td>{item.countSuccessCheck }/<span className={item.countFaileCheck == 0?'success':'faile'}>{item.countFaileCheck}</span></td>
                {/* <td dangerouslySetInnerHTML={{__html: item.lastcheckViettelWifi}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckVPNT}}></td>
                <td dangerouslySetInnerHTML={{__html: item.lastcheckFPT}}></td>
                <td>{item.countSuccessCheckWifi }/<span className={item.countFaileCheckWifi == 0?'success':'faile'}>{item.countFaileCheckWifi}</span></td> */}
                {/* <td><button onClick={() => clickButton(item.url)} className='button'>Góp ý</button></td> */}
            </tr>
          ))}
          </tbody>
      </table>
      <ToastContainer />
    </div>
  );
}

export default App;
